/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// components
import { fetchData } from "../../services/apiService";

//styles
import "../../assets/css/components/filter_whs.css";

const url_categories = "/api/list/categories";

export const FilterWhs = ({onCategorySelected, onSeasonSelected}) => {
  const
    [selectedFilter, setSelectedFilter] = useState(null),
    [filters, setFilters] = useState([])
  ;

  const fetchFilters = async () => {
    const data = await fetchData(url_categories);
    setFilters(data.data);
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter.title);
    onCategorySelected(filter.id);
  };

  const handleClearFilters = () => {
    onCategorySelected(null);

    let cells = document.querySelectorAll(".cell_kanban");

    if (cells.length > 0) {
      cells.forEach(function (element) {
        element.classList.remove("cell_filter");
      });
    }

    if (onSeasonSelected !== undefined) {
      onSeasonSelected(null);
    }
    setSelectedFilter("");
  }

  return (
    <div className="filter_whs">
      <div className="filter_buttons">
        <button className="btn_clear_filter" onClick={handleClearFilters}>clear</button>
        {filters.map((filter) => (
          <button
            key={filter.title}
            className={`filter_button ${filter.title} ${selectedFilter === filter.title ? "active" : ""}`}
            onClick={() => handleFilterClick(filter)}
            style={{ backgroundColor: filter.color }}
            id={filter.id}
          >
           <p className="text">
           {filter.title}
           </p>
          </button>
        ))}
      </div>
    </div>
  );
};
