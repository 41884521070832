import { useState } from "react";

// resources
import download from "../../assets/img/svg/download.svg";
import expand from "../../assets/img/svg/expand.svg";

// styles
import "../../assets/css/components/card_tools.css";

const CardTools = ({ images, title = "Title", categories = [], toolkit = "", setOnExpand, setIsModalOpen, index, totalCards, currentCardIndex }) => {
  const
    [currentImageIndex, setCurrentImageIndex] = useState(0)
  ;

  const handleNextImage = (e) => {
    const parentContainer = e.target.closest(".card_container_tools");
    const imagePlaceholders = parentContainer.querySelectorAll(".image_placeholder");

    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );

    imagePlaceholders.forEach((placeholder) => {
      const imgElement = placeholder.querySelector(".image");
      imgElement.src = images[currentImageIndex].url;
    });
  };

  const handlePrevImage = (e) => {
    const parentContainer = e.target.closest(".card_container_tools");
    const imagePlaceholders = parentContainer.querySelectorAll(".image_placeholder");

    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );

    imagePlaceholders.forEach((placeholder) => {
      const imgElement = placeholder.querySelector(".image");
      imgElement.src = images[currentImageIndex].url;
    });
  };

  const openToolkit = () => {
    if (toolkit !== "") {
      window.open(toolkit, "_black");
    }
  }

  const handleOnExpand = (img) => {
    setOnExpand(img);
    setIsModalOpen(true);
    document.querySelector(".user_menu").classList.add("hide");
  }

  return (
    <div className={`card_container_tools ${currentCardIndex === index ? "" : "hide"}`}>
      <div className="categories_container">
        {categories.map((category, index) => (
          <div
            key={index}
            className={`category_inside ${category.name} cat_${category.id}`}
            style={{backgroundColor: category.color}}
          />
        ))}
      </div>

      <div className="image_container">
        <picture className="image_placeholder first_image">
          {images[currentImageIndex]?.url && (
            <img src={images[currentImageIndex].url} alt={title} className="image"/>
          )}
        </picture>

        <button
          className="control_button"
          onClick={() => handleOnExpand(images[currentImageIndex]?.url)} // Pasa la imagen actual
        >
          <img src={expand} alt="Expand" className="image_expand"/>
        </button>

        <button
          className={`download_button ${toolkit !== "" ? "" : "no_visible"}`}
          onClick={openToolkit}
        >
          <img src={download} alt={expand} className="download_toolkit"/>
        </button>
      </div>

      <div className={`slider_controls ${images.length <= 1 ? "no_visible" : ""}`}>
        <button className="prev_button" onClick={handlePrevImage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#000000"
          >
            <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/>
          </svg>
        </button>
        <button className="next_button" onClick={handleNextImage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#000000"
          >
            <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/>
          </svg>
        </button>
      </div>
      <div className="title">{title}</div>

    </div>
  );
};

export default CardTools;
