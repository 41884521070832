import { useEffect, useState } from "react";
import { fetchData } from "../../services/apiService.js";
import { useParams } from "react-router-dom";

// components
import NavigationBar from "../../components/NavigationBar/NavigationBar"
import { MenuUser } from "../../components/MenuUser/MenuUser.js";
import ButtonBack from "../../components/ButtonBack/ButtonBack.js";
import { FilterWhs } from "../../components/FilterWhs/FilterWhs";
import Segments from "../../components/Segments/Segments";
import Gantt from "../../components/Gantt/Gantt";
import FullScreen from "../../components/FullScreen/FullScreen";
import { FilterBasic } from "../../components/FilterWhsBasic/FilterWhsBasic";

// resources
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";

// styles
import "../../assets/css/pages/whs.css";

const WHS = () => {
  const
    [segments, setSegments] = useState([]),
    [categoryFilterSelected, setCategoryFilterSelected] = useState(null),
    [seasonFilterSelected, setSeasonFilterSelected] = useState(null),
    [historyActivityID, setHistoryActivityID] = useState(new Set()),
    [resetHistory, setResetHistory] = useState(false)
  ;

  const
    params = useParams()
  ;

  const fetchSegments = async () => {
    try {
      const response = await fetchData(`/api/list/segments`);
      // console.dir(response.data);
      setSegments(response.data);
    } catch(error) {
      console.error("Error fetching Segments:", error)
    }
  };

  const handleChangeCategory = (idCategory) => {
    setCategoryFilterSelected(idCategory);
  }
  const handleChangeSeason = (idSeason) => {
    setSeasonFilterSelected(idSeason);
  }

  const handleLinkClick = () => {
    setResetHistory(true);
  };

  useEffect(() => {
    if (resetHistory) {
      setHistoryActivityID(new Set());
      setResetHistory(false);
    }
  }, [resetHistory]);

  useEffect(() => {
    fetchSegments();
  }, []);

  return (
    <div className="container_whs layout_1_j">
      <NavigationBar className="j_1" styleType={"close"} />
      <MenuUser/>
      <ButtonBack />

      <div className="container_page j_2">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_white} alt="adidas" className="img" />
        </picture>

        <h1 className="title_whs">
          OMNI LAM CALENDAR
        </h1>
        <h2 className="subtitle_whs">
          OMNI LAM CALENDAR
        </h2>
        <h3 className="subtitle2_whs">
          {sessionStorage.getItem("segment_title_now")}
        </h3>
        <p className="desc_whs">
          Puedes filtrar tu búsqueda con los siguientes tags de categorías.
        </p>

        <div className="content_filters">
          <div className="title_find">
            Filtrar por
          </div>
          <div className="layout_filters">
            <FilterBasic onSeasonSelected={handleChangeSeason}/>
            <FilterWhs onCategorySelected={handleChangeCategory} onSeasonSelected={handleChangeSeason}/>
            <FullScreen type="WHS"/>
          </div>
        </div>

        <Gantt
          params={params}
          categorySelected={categoryFilterSelected}
          seasonSelected={seasonFilterSelected}
          historyActivityID={historyActivityID}
          setHistoryActivityID={setHistoryActivityID}
        />

        <Segments
          segments={segments}
          onLinkClick={handleLinkClick}
        />
      </div>
    </div>
  );
};

export default WHS;
