const ErrorMessage = () => {
  return (
    <div className="container_error_message">
      <h3 className="subtitle">
        Error
      </h3>
      <h2 className="title">
        ¡LO LAMENTAMOS!
      </h2>
      <p className="description">
        datos no encontrados
      </p>
    </div>
  );
};

export default ErrorMessage;
