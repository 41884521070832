import { Link } from "react-router-dom";

// components
import GetLink from "../../components/GetLink/GetLink.js";

// styles
import "../../assets/css/components/segments.css";

const Segments = ({ segments, onLinkClick }) => {
  const renderButtonSegment = (segments) => (
    <p className="text">{segments.title}</p>
  )

  return (
    <div className="segments_container">
      <h2 className="segments_title">Explora más segmentos</h2>
      <div className="segments_list">
      {segments.map((segment) => {
        const linkTo = GetLink({segments: segment});
        return (
          <div key={segment.id} className="segment_button">
          {linkTo ? (
            <Link
            to={linkTo}
            className="link_segment"
            onClick={() => onLinkClick && onLinkClick()}
            >
            {renderButtonSegment(segment)}
            </Link>
          ) : (
            renderButtonSegment(segment)
          )}
          </div>
        )
      })}
      </div>
    </div>
  );
};

export default Segments;
