/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { fetchData } from "../../services/apiService.js";
import { useParams } from "react-router-dom";

// components
import { MenuUser } from "../../components/MenuUser/MenuUser.js";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import ButtonBack from "../../components/ButtonBack/ButtonBack.js";
import { FilterWhs } from "../../components/FilterWhs/FilterWhs";
import Kanban from "../../components/Kanban/Kanban";
import FullScreen from "../../components/FullScreen/FullScreen";
import Modal from "../../components/Modal/Modal";
import Segments from "../../components/Segments/Segments";

// resources
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";
import arrowBtn from "../../assets/img/svg/arrow_btn.svg";

// styles
import "../../assets/css/pages/sla.css";
import TypeCampaingCard from "../../components/TypeCampaingCard/TypeCampaingCard";
import CardTools from "../../components/CardTools/CardTools";

const SLA = () => {
  const params = useParams();

  const
    [segments, setSegments] = useState([]),
    [categoryFilterSelected, setCategoryFilterSelected] = useState(null),
    [isTooltipVisible, setTooltipVisible] = useState(false),
    [isFullKanban, setIsFullKanban] = useState(false),
    [isSpecial, setIsSpecial] = useState(false),
    [specialsArray, setSpecialsArray] = useState([]),
    [onExpand, setOnExpand] = useState([]),
    [isModalOpen, setIsModalOpen] = useState(false)
  ;


  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.querySelector(".user_menu").classList.remove("hide");
  };

  const showSpecial = (e) => {
    e.preventDefault();
    setIsSpecial(!isSpecial);
  };

  const fetchDataCampaigns = async () => {
    const url_sla = `/api/sla/${params.idSegment}`;

    try {
      const response = await fetchData(url_sla);

      let campaingsArray = [];

      response.data.forEach((element) => {
        element.type_campaigns.forEach((element) => {
          campaingsArray.push(element);
        });
      });
    } catch(error) {
      console.error("Error fetching SLA:", error);
    }
  };

  const fetchEspecialCampaigns = async () => {
    const data = await fetchData(`/api/sla-specials/${params.idSegment}`);

    let specialsArray = [];
    data.data.forEach((element) => {
      element.type_campaigns.forEach((element) => {
        specialsArray.push(element);
      });
      setSpecialsArray(specialsArray);
    });
  };

  const fetchSegments = async () => {
    const url_segments = "/api/list/segments";
    try {
      const response = await fetchData(url_segments);
      setSegments(response.data);
    } catch (error) {
      console.error("Error fetching segments:", error);
    }
  };


  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const handleChangeCategory = (idCategory) => {
    setCategoryFilterSelected(idCategory);

    let cells = document.querySelectorAll(".cell_kanban");

    if (cells.length > 0) {
      cells.forEach(function (element) {
        element.classList.add("cell_filter");
      });
    }
  }

  useEffect(() => {
    fetchDataCampaigns();
    fetchSegments();
    fetchEspecialCampaigns();
  }, []);

  return (
    <div className={`container_sla layout_1_j ${isFullKanban ? "fullKanban" : ""}`}>
      <NavigationBar className="j_1" styleType={"close"} />
      <MenuUser />
      <ButtonBack />

      <div className="container_page j_2">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_white} alt="adidas" className="img" />
        </picture>

        <h1 className="title_whs">OMNI LAM CALENDAR</h1>
        <h2 className="subtitle_whs">OMNI LAM CALENDAR</h2>
        <h3 className="subtitle2_whs">
          {sessionStorage.getItem("segment_title_now")}
        </h3>
        <p className="desc_whs">
          Puedes filtrar tu búsqueda con los siguientes tags de categorías.
        </p>
        <div className="content_filters">
          <div className="title_find">Filtrar por</div>
          <div className="layout_filters">
            <FilterWhs onCategorySelected={handleChangeCategory}/>
            <FullScreen
              type={isSpecial ? "special" : "SLA"}
              setIsFullKanban={setIsFullKanban}
            />
          </div>

          <h3 className="title_tools no_visible">RETAIL TOOLS</h3>
          <div className={`container_sla_sections ${isSpecial ? "active" : ""}`}>
            <Kanban
              idSegment={params.idSegment}
              categorySelected={categoryFilterSelected}
              setOnExpand={setOnExpand}
              setIsModalOpen={setIsModalOpen}
            />

            <div className="sla_specialist item_container_sla">

              {
                specialsArray && specialsArray.length !== 0 ?
                  specialsArray.map((special, index) => (
                    <div className="card_specialist" key={special.id}>
                      <div className="header_card layout_5_l">
                        <h3 className="title_card a_text_1">
                          SPECIAL CAMPAIGN {String(index + 1).padStart(2, '0')}
                        </h3>
                        <div className="title_content a_text_2">
                          {index === 0 && (
                            <span className="header_text title_moment">MOMENTOS</span>
                          )}
                          <span className="header_text">
                            TBD (Copy de todos los ítems que abarcarán dicha gráfica)
                          </span>
                        </div>
                      </div>
                      <div className="data_container">
                        <div className="sidebar_left">
                          <div className="vertical_text">SLA - MULTISPECIALIST</div>
                        </div>
                        <div className="content_section">
                          <TypeCampaingCard
                            key={special.id}
                            title={special.type_campaign}
                            campaings={special.campaigns}
                            special={true}
                          />
                          <div className="horizontal_section">
                            <div className="cards_container" id="cardsContainer">
                              {special.cards_gallery && special.cards_gallery.length !== 0 ?
                                special.cards_gallery.map((card) => (
                                    <CardTools
                                      key={card.id}
                                      images={card.images}
                                      title={card.title}
                                      categories={card.categories}
                                      toolkit={card.link_toolkit_gallery}
                                      setOnExpand={setOnExpand}
                                      setIsModalOpen={setIsModalOpen}
                                    />

                                ))
                                : (<p>No se encontraron</p>)
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  ))
                  : (<p>No se encontraron</p>)
            }


          </div>
            {isModalOpen && (
              <Modal image={onExpand} onClose={handleCloseModal} />
            )}
          </div>

          <div className="navigator_buttons">
            <h4 className="title hide">Ver más campañas</h4>
            <button className={`btn_showCampains ${isSpecial ? "active_btn" : ""}`} onClick={showSpecial}>
              <span className="text_btn">
                {isSpecial ? "Ver Campañas" : "Ver campañas especiales"}
              </span>
              <img className="img_btn" src={arrowBtn} alt=""/>
            </button>
            <div className="container_btns hide">
              <button className="control" onClick={toggleTooltip}>
                &#8593;
              </button>
              <div className="counter-container">
                <div className="counter">1</div>
                {isTooltipVisible && (
                  <div className="tooltip">Próximamente</div>
                )}
              </div>
              <button className="control" onClick={toggleTooltip}>
                &#8595;
              </button>
            </div>
          </div>

          <Segments segments={segments} />
        </div>
      </div>
    </div>
  );
};

export default SLA;

