import { useState, useCallback, useEffect } from "react";
import { fetchData } from "../../services/apiService";

// styles
import "../../assets/css/components/season_holding.css";

const SeasonHolding = ({ onSeasonChange }) => {
  const
    [activeButton, setActiveButton] = useState(null),
    [seasons, setSeasons] = useState([])
  ;

  const url_season = "/api/list/season-modules";

  const fetchSeason = useCallback(async () => {
    try {
      const response = await fetchData(url_season);
      // console.dir(response.data);
      setSeasons(response.data);

      const defaultSeason = response.data.find((season) => season.default === true);
      if (defaultSeason) {
        setActiveButton(defaultSeason.id);
        onSeasonChange(defaultSeason.id);
      }
    } catch (error) {
      console.error("Error fetching Seasons:", error);
    }
  }, [onSeasonChange]);

  const handleClick = (id) => {
    setActiveButton(id);
    onSeasonChange(id);
  };

  useEffect(() => {
    fetchSeason();
  }, [fetchSeason]);

  return (
    <div className="container_season">
      <h4 className="title">Temporada</h4>
      <div className="container_btns">
        {seasons.map((button) => (
          <div key={button.id} style={{ position: "relative" }}>
            <button
              className={`btn_season ${
                activeButton === button.id ? "active" : ""
              }`}
              onClick={() => handleClick(button.id)}
            >
              {button.title}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeasonHolding;

