import React, {useEffect, useState} from "react";

//styles
import "../../assets/css/components/filter_whs.css";
import {fetchData} from "../../services/apiService";
export const FilterBasic = ({onSeasonSelected, page="whs"}) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [season, setSeason] = useState([]);

  useEffect(() => {
    fetchSeasons();
  }, []);

  const fetchSeasons = async () => {
    const data = await fetchData(`/api/list/season-campaign`);
    setSeason(data.data);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter.id);
    onSeasonSelected(filter.id)
  };

  return (
    <div className={`filter_basic ${page}`}>
      <div className="filter_buttons">
        {season.map((filter) => (
          <button
            key={filter.id}
            className={`filter_button ${filter.id} ${
              selectedFilter === filter.id ? "active" : ""
            }`}
            onClick={() => handleFilterClick(filter)}
          >
            <p className="text">{filter.title}</p>
          </button>
        ))}
      </div>
    </div>
  );
};
