import { useEffect, useState, useCallback, useRef } from "react";
import { fetchData } from "../../services/apiService";

// components
import CardTools from "../../components/CardTools/CardTools.js";
import TypeCampaingCard from "../../components/TypeCampaingCard/TypeCampaingCard";
import Loading from "../../components/Loading/Loading";

// resources
import sliderControl from "../../assets/img/svg/arrow_slider.svg";

// styles
import "../../assets/css/components/kanban.css";
import "../../assets/css/components/loading.css";

const Kanban = ({ idSegment, categorySelected, setIsFullImage, setOnExpand, setIsModalOpen }) => {
  const
    [categories, setCategories] = useState([]),
    [activities, setActivities] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [typeCampaing, setTypeCampaing] = useState([]),
    [currentCardIndexByCell, setCurrentCardIndexByCell] = useState({}), // Estado para el índice de cada celda
    containerKanbanRef = useRef(null)
  ;

  useEffect(() => {
    const allElements = document.querySelectorAll(".category_inside");
    const filterElements = document.querySelectorAll(`.container_sla_sections  .cat_${categorySelected}`);

    if (categorySelected !== null) {
      allElements.forEach((element) => {
        const card_filter = element.parentElement.parentElement;
        card_filter.classList.add("no_visible");
      });

      filterElements.forEach((element) => {
        const card_filter = element.parentElement.parentElement;
        card_filter.classList.remove("no_visible");
      });
    } else {
      allElements.forEach((element) => {
        const card_filter = element.parentElement.parentElement;
        card_filter.classList.remove("no_visible");
      });
    }
  }, [categorySelected]);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await fetchData(`/api/list/distribution-areas/${idSegment}`);
      if (response && response.data && Array.isArray(response.data)) {
        setCategories(response.data);
      } else {
        console.error("Expected an array but got:", response);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  }, [idSegment]);

  const fetchDataCampaigns = useCallback(async () => {
    try {
      const response = await fetchData(`/api/sla/${idSegment}`);
      if (response && response.data) {
        setActivities(response.data);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  }, [idSegment]);

  const extractTypeCampaing = useCallback(() => {
    if (activities.length > 0) {
      const titles = activities.map((activity) =>
        activity.type_campaigns.map((t_campaigns) => (
          <TypeCampaingCard
            key={t_campaigns.id}
            title={t_campaigns.type_campaign}
            campaings={t_campaigns.campaigns}
          />
        ))
      );
      setTypeCampaing(titles);
    }
  }, [activities]);

  const handleNextCard = (cellId, totalCards) => {
    setCurrentCardIndexByCell((prevState) => ({
      ...prevState,
      [cellId]: (prevState[cellId] + 1) % totalCards,
    }));
  };

  const handlePrevCard = (cellId, totalCards) => {
    setCurrentCardIndexByCell((prevState) => ({
      ...prevState,
      [cellId]: prevState[cellId] === 0 ? totalCards - 1 : prevState[cellId] - 1,
    }));
  };

  // Establecer el estado inicial solo una vez cuando las actividades cambian
  useEffect(() => {
    const initialIndexState = {};
    activities.forEach((activity) => {
      activity.type_campaigns.forEach((typeCampaign) => {
        typeCampaign.cards_gallery.forEach((card) => {
          const cellId = `${typeCampaign.id}_${card.distribution_area.id}`;
          if (!initialIndexState[cellId]) {
            initialIndexState[cellId] = 0;
          }
        });
      });
    });
    setCurrentCardIndexByCell(initialIndexState);
  }, [activities]);

  useEffect(() => {
    fetchCategories();
    fetchDataCampaigns();
  }, [idSegment, fetchDataCampaigns, fetchCategories]);

  useEffect(() => {
    extractTypeCampaing();
  }, [activities, extractTypeCampaing]);

  // useEffect para posicionar el Kanban
  useEffect(() => {
    const firstCard = document.querySelectorAll(".container_kanban .card_container_tools:not(.hide)");

    let
      minOffsetTop = Infinity,
      minOffsetLeft = Infinity
    ;

    firstCard.forEach((card) => {
      const
        offsetTop = card.offsetTop,
        offsetLeft = card.offsetLeft
      ;

      if (offsetTop < minOffsetTop) {
        minOffsetTop = offsetTop;
      }

      if (offsetLeft < minOffsetLeft) {
        minOffsetLeft = offsetLeft;
      }
    });

    if (minOffsetTop !== Infinity && containerKanbanRef.current) {
      containerKanbanRef.current.scrollTop = minOffsetTop - 45;
    }

    if (minOffsetLeft !== Infinity && containerKanbanRef.current) {
      containerKanbanRef.current.scrollLeft = minOffsetLeft;
    }
  }, [typeCampaing]);

  if (isLoading) {
    return ( <Loading /> );
  }

  return (
    <section className="container_kanban item_container_sla" ref={containerKanbanRef}>
      <div className="container_title_kanban">
        <div className="title_activity">SLA - MULTISPECIALIST</div>
        <div className="group_moments">
          <div className="title_distribution_area">MOMENTOS</div>
          {typeCampaing}
        </div>
      </div>
      <div className="wrapper_kanban">

        <div className="row_kanban header_kanban">
          {Array.isArray(categories) && categories.length > 0 ? (
            categories.map((category) => (
              <div key={category.id} className="title_distribution_area">
                {category.title}
              </div>
            ))
          ) : (
            <div>No categories available</div>
          )}
        </div>

        {/* Crear filas para cada type_campaign en cada actividad */}
        {activities.map((activity) =>
          activity.type_campaigns.map((typeCampaign) => {
            // Agrupamos las tarjetas de cards_gallery por distribution_area.id antes de iterar sobre categories
            const groupedCards = typeCampaign.cards_gallery.reduce((acc, card) => {
              const areaId = card.distribution_area.id;

              let group = acc.find(group => group.id === areaId);

              if (group) {
                group.cards.push(card);
              } else {
                acc.push({
                  id: areaId,
                  cards: [card]
                });
              }

              return acc;
            }, []);

            return (
              <div key={typeCampaign.id} className="row_kanban">
                {categories.map((category) => {
                  const matchedGroup = groupedCards.find(group => group.id === category.id);
                  const totalCards = matchedGroup ? matchedGroup.cards.length : 0;
                  const cellId = `${typeCampaign.id}_${category.id}`;

                  return (
                    <div
                      key={cellId}
                      className={`cell_kanban element_${typeCampaign.id}_${category.id}`}
                    >
                      {matchedGroup ? (
                        matchedGroup.cards.map((card, index) => (
                          <CardTools
                            key={card.id}
                            images={card.images}
                            title={card.title}
                            categories={card.categories}
                            toolkit={card.link_toolkit_gallery}
                            setIsFullImage={setIsFullImage}
                            setOnExpand={setOnExpand}
                            setIsModalOpen={setIsModalOpen}
                            index={index}
                            totalCards={matchedGroup.cards.length}
                            currentCardIndex={currentCardIndexByCell[cellId]} // Control específico para la celda
                          />
                        ))
                      ) : null}

                      {totalCards > 1 && (
                        <div className="slider_btn">
                          <button onClick={() => handlePrevCard(cellId, totalCards)} className="prev btn_slider_btn">
                            <img src={sliderControl} alt="Next Campaign" className="img" />
                          </button>
                          <h3 className="content_slider_btn">Ver item en otra campaña</h3>
                          <button onClick={() => handleNextCard(cellId, totalCards)} className="next btn_slider_btn">
                            <img src={sliderControl} alt="Next Campaign" className="img" />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </section>
  );
};

export default Kanban;
