import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// components
import { MenuUser } from "../../components/MenuUser/MenuUser.js";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import ButtonBack from "../../components/ButtonBack/ButtonBack.js";
import SnapShot from "../../components/SnapShot/SnapShot.js";
import Segments from "../../components/Segments/Segments";
import Modal from "../../components/Modal/Modal";
import FullScreen from "../../components/FullScreen/FullScreen";
import { fetchData } from "../../services/apiService";

// resources
import logo_adidas_white from "../../assets/img/svg/adidas_white.svg";
import download_2 from "../../assets/img/svg/download_2.svg";

// styles
import "../../assets/css/pages/examples.css";

const
  url_segments = "/api/list/segments"
;

const Examples = () => {
  const
    [isModalOpen, setIsModalOpen] = useState(false),
    [onExpand, setOnExpand] = useState([]),
    [segments, setSegments] = useState([]),
    [isFullKanban, setIsFullKanban] = useState(false)
  ;

  const
    params = useParams()
  ;

  const fetchSegments = async () => {
    const data = await fetchData(url_segments);
    setSegments(data.data);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.querySelector(".user_menu").classList.remove("hide");
  };

  useEffect(() => {
    fetchSegments();
  }, []);


  return (
    <div className={`container_example layout_1_j ${isFullKanban ? "fullScreen" : ""}`}>
      <NavigationBar className="j_1" styleType={"close"} />
      <MenuUser />
      <ButtonBack />

      <div className="container_page j_2">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_white} alt="adidas" className="img" />
        </picture>

        <h1 className="title_examples">
          OMNI LAM CALENDAR
        </h1>
        <h2 className="subtitle_examples">
          OMNI LAM CALENDAR
        </h2>
        <h3 className="subtitle2_examples">
          EXAMPLE WK SNAPSHOT
        </h3>
        <p className="desc_examples">
          Desarrolla, visualiza y descarga muestra de tu concepto
          <img src={download_2} alt="adidas" className="img" />
        </p>

        <div className="content_examples">
          <FullScreen
            type= "example"
            setIsFullKanban={setIsFullKanban}
          />
          <SnapShot
            params={params}
            setOnExpand={setOnExpand}
            setIsModalOpen={setIsModalOpen}
          />
          <Segments segments={segments} />
        </div>
      </div>

      {isModalOpen && (
        <Modal image={onExpand} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default Examples;
