// src/pages/NotFound/NotFound.js
import React from "react";
import logo_adidas_black from "../../assets/img/svg/adidas_black.svg";
import { Link } from "react-router-dom";


// styles
import "../../assets/css/pages/notfound.css";
import arrowBtn from "../../assets/img/svg/arrow_btn_w.svg";

function NotFound() {
  return (
    <div className="notfound">
      <div className="container_title">
        <picture className="single_logo_adidas">
          <img src={logo_adidas_black} alt="adidas" className="img" />
        </picture>

        <h1 className="title_whs">OMNI LAM CALENDAR</h1>
        <h2 className="subtitle_whs">OMNI LAM CALENDAR</h2>
      </div>
      <div className="container_text">
        <h1 className="title">Error 404</h1>
        <h2 className="subtitle">¡LO LAMENTAMOS!</h2>
        <p className="text_desc">
          Parece que la url no es correcta, pero no te preocupes puedes volver a la página de inicio
        </p>
        <Link className="btn_back" to="/holding">
          <span className="text_btn">
            IR A LA PÁGINA INICIO
          </span>
          <img className="img_btn" src={arrowBtn} alt=""/>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
